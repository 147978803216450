import { mapState, mapGetters } from 'vuex';
import {
  fetchGroupsById,
  emptyCalificacion,
  fetchEvaluationPeriods,
  fetchPerformanceLevel,
  fetchQualifications,
  getStudentById,
} from '../../../configuration/helpers/KnGroupsOptions';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import { gradesPost, gradesUpdate } from '../../helpers/gradeOptions';
import { fetchSubjectStudents } from '../../helpers/syllabusOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
import { getItem } from '../../../../api/api-methods';

export default {
  components: {
    KnFormTitle,
    KnLocalAlert,
  },
  mixins: [generalRepoMixin, paginationMixin, validationFormMixin],
  data() {
    return {
      tab: 0,
      qualificationAprobada: [],
      selectedQualifications: [],
      selectedQualification: null,
      qualificationById: null,
      studentBandera: false,
      qualifications: [],
      routerName: 'Calificaciones',
      total: 0,
      totalArray: [],
      alertText: '',
      bandera: false,
      emptyCalificacion,
      promedio: 0,
      dataComentario: {},
      finalGrade: null,
      gradeId: null,
      studentId: null,
      studentArray: [],
      subjectArray: [],
      mixedArray: [],
      evaluationCriteria: [],
      expanded: [],
      evaluationPeriods: [],
      subjectStudents: [],
      errors: [],
      subjectTeacher: [],
      selectedStudentIds: [],
      selectedStudentId: [],
      promedioTotal: null,
      userName: null,
      isNewNotes: true,
      showAlert: false,
      group: undefined,
      loading: false,
      warnings: [],
      materiaProfesorId: null,
      calificaciones: {
        id_boleta: 1,
        id_materia_alumno: null,
        id_periodo: null,
        id_estatus_calificacion: null,
        id_institucion_educativa: null,
        fecha_evaluacion: null,
      },
      evaluationType: null,
      selectedItems: [],
      numericGrade: null,
      qualitativeGrade: null,
      performanceLevels: [],
      allSelected: false,
      evaluationTypes: [
        { id: 1, nombre: 'Calificación numérica' },
        { id: 2, nombre: 'Calificación cualitativa' },
      ],
      qualitativeOptions: [
        'Excelente',
        'Muy bueno',
        'Bueno',
        'Regular',
        'Insuficiente',
      ],
      headers: [
        { text: 'Materia', value: 'nombre' },
        {
          text: 'Criterios a calificar',
          sortable: false,
        },
        { text: 'ID', value: 'id', class: 'purple--text', sortable: false },
      ],
      secondHeaders: [
        {
          text: 'Alumno',
          value: 'nombreCompleto',
          class: 'purple--text',
          sortable: false,
        },
        {
          value: 'criterios_evaluacion',
          sortable: false,
        },
        { text: 'Comentarios', value: 'comentarios', sortable: false },
        { text: 'Estatus calificación', value: 'estatus', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      thirdHeaders: [
        {
          text: 'Alumno',
          value: 'materia_alumno.nombreCompleto',
          class: 'purple--text',
          sortable: false,
        },
        { value: 'criterios_evaluacion' },
        { text: 'Comentarios', value: 'comentarios', sortable: false },
        { text: 'Estatus calificación', value: 'estatus', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      fourthHeaders: [
        {
          text: 'Alumno',
          value: 'materia_alumno.nombreCompleto',
          class: 'purple--text',
          sortable: false,
        },
        { text: 'Periodo', value: 'periodo.dato' },
        {
          text: 'Estatus',
          value: 'estatus_calificacion.dato',
          sortable: false,
        },
      ],
      editar: false,
    };
  },

  async created() {
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.gradeId = this.validateId(paramId);
        await this.getPaginatedPerformanceLevel(1);
        await this.getPaginatedFetchQualifications(1);
        await this.getPaginatedQualification(1);
        await this.loadDataGrade();
        this.periodoEvaluacion = await this.getPaginatedevaluationPeriods(1);
        this.calificaciones.id_institucion_educativa = this.institutionId;
        //await this.getPaginatedSubjectTeachers(1)
        this.studentArray.forEach((student) => {
          student.estatus = 'Sin asignar';
        });
        this.qualifications.forEach((calificaciones) => {
          calificaciones.estatus = 'Borrador';
        });
        this.qualificationAprobada.forEach((calificacionPendiente) => {
          calificacionPendiente.estatus = 'Pendiente';
        });
      } else return undefined;
    } finally {
      this.loadingPage = false;
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),

    successAlertText() {
      return this.isNewNotes
        ? `Calificación mandada con exito! \nNombre: ${this.selectedStudentIds[0].datos_personales}, promedio: ${this.promedio}`
        : '';
    },
    dangerAlertText() {
      return `Ocurrio un error inesperado`;
    },
    dangerAlertType() {
      return 'warning';
    },
    successAlertType() {
      return this.isNewNotes ? 'success' : 'info';
    },
    isNumericOrDefault() {
      return this.evaluationType === 1 || this.evaluationType === null;
    },
    // Computada para mostrar el v-select si es calificación cualitativa
    isQualitative() {
      return this.evaluationType === 2;
    },
  },
  methods: {
    //#region peticiones get
    async getPaginatedevaluationPeriods(page = 1) {
      try {
        (this.evaluationPeriods = []), this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;
          const { ok, data, message, count } = await fetchEvaluationPeriods({
            institutionId: this.institutionId,
            systemStatus: true,
            startDate: '2024-08-01',
            endDate: '2025-01-31',
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            this.setPaginationCount(count);
            for (const evaluationPeriod of data) {
              this.evaluationPeriods.push({ ...evaluationPeriod });
            }
          } else {
            console.error(
              'No se pudieron obtener los periodos de evaluación',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },
    async getPaginatedPerformanceLevel(page = 1) {
      try {
        this.performanceLevels = [];
        this.setPaginationPage(page);
        if (!this.loading) {
          this.loading = true;

          const { ok, data, message, count } = await fetchPerformanceLevel({
            systemStatus: true,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            for (const performanceLevel of data) {
              this.performanceLevels.push({ ...performanceLevel });
            }
          } else {
            console.error(
              'No se pudieron obtener los niveles de desempeño',
              message
            );
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los niveles de desempeño:', error);
        this.loading = false;
      }
    },
    async getPaginatedSubjectStudents(page = 1) {
      try {
        this.subjectStudents = [];
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;
          for (const element of this.selectedStudentIds) {
            if (element !== null && element !== undefined) {
              const { ok, data, message, count } = await fetchSubjectStudents({
                institutionId: this.institutionId,
                systemStatus: true,
                studentId: element.id,
                subjectTeacherId: this.materiaProfesorId,
                limit: this.pagination.limit,
                offset: this.pagination.offset,
              });

              if (ok) {
                this.setPaginationCount(count);
                for (const subjectStudents of data) {
                  this.subjectStudents.push({ ...subjectStudents });
                }
              } else {
                console.error(
                  'No se pudieron obtener los periodos de evaluación',
                  message
                );
              }

              this.loading = false;
            }
          }
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },
    async getPaginatedQualification(page = 1) {
      try {
        this.qualificationAprobada = [];
        this.setPaginationPage(page);
        if (!this.loading) {
          this.loading = true;
          const { ok, data, message, count } = await fetchQualifications({
            estatus_calificacion: 'Pendiente',
            systemStatus: true,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            this.setPaginationCount(count);
            for (const qualificationAprobada of data) {
              this.qualificationAprobada.push({ ...qualificationAprobada });
              const studentResponse = await getStudentById(
                qualificationAprobada.materia_alumno.alumno
              );
              if (studentResponse) {
                const qualificationToUpdate = this.qualificationAprobada.find(
                  (q) => q.id === qualificationAprobada.id
                );

                if (qualificationToUpdate) {
                  qualificationToUpdate.materia_alumno.nombreCompleto =
                    getFullName(studentResponse.datos_personales);
                }
              }
            }
          } else {
            console.error(
              'No se pudieron obtener los periodos de evaluación',
              message
            );
          }
          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener los periodos de evaluación:', error);
        this.loading = false;
      }
    },
    async getPaginatedFetchQualifications(page = 1) {
      try {
        this.setPaginationPage(page);

        if (!this.loading) {
          this.loading = true;

          // Obtener calificaciones
          const { ok, data, message, count } = await fetchQualifications({
            estatus_calificacion: 'Borrador',
            systemStatus: true,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);

            for (const qualifications of data) {
              // Verificar si la calificación ya existe en el array
              const existingQualification = this.qualifications.find(
                (q) => q.id === qualifications.id
              );

              // Si ya existe, no la volvemos a agregar
              if (!existingQualification) {
                // Agregar la calificación al array
                this.qualifications.push({
                  ...qualifications,
                  materia_alumno: { ...qualifications.materia_alumno },
                  criterios_evaluacion: [],
                  isSend: false,
                });
              }

              // Cargar datos del estudiante por su ID
              const studentResponse = await getStudentById(
                qualifications.materia_alumno.alumno
              );
              if (studentResponse) {
                const qualificationToUpdate = this.qualifications.find(
                  (q) => q.id === qualifications.id
                );

                if (qualificationToUpdate) {
                  qualificationToUpdate.materia_alumno.nombreCompleto =
                    getFullName(studentResponse.datos_personales);
                }
              }

              // Cargar criterios de evaluación
              const detailQualifications = await getItem(
                `/app-personas/filters/detalle-calificacion?calificacion=${qualifications.id}`
              );

              if (detailQualifications && detailQualifications.results) {
                const qualificationToUpdate = this.qualifications.find(
                  (q) => q.id === qualifications.id
                );

                if (qualificationToUpdate) {
                  detailQualifications.results.forEach((evaluationCriteria) => {
                    // Evitar agregar duplicados en `criterios_evaluacion`
                    const exists =
                      qualificationToUpdate.criterios_evaluacion.some(
                        (c) =>
                          c.id === evaluationCriteria.criterio_evaluacion.id
                      );

                    if (!exists) {
                      qualificationToUpdate.criterios_evaluacion.push({
                        ...evaluationCriteria.criterio_evaluacion, // Detalles del criterio
                        puntaje_obtenido: evaluationCriteria.puntaje_obtenido, // Puntaje obtenido
                        puntaje_total: evaluationCriteria.puntaje_total, // Puntaje total
                        comentarios: evaluationCriteria.calificacion.comentario, // Comentarios
                      });
                    }
                  });
                }
              }

              console.log('Calificación actualizada:', this.qualifications);
            }
          } else {
            console.error('No se pudieron obtener las calificaciones', message);
          }

          this.loading = false;
        }
      } catch (error) {
        console.error('Error al obtener las calificaciones:', error);
        this.loading = false;
      }
    },
    async loadDataGrade() {
      try {
        if (this.employeeId !== null && this.employeeId !== undefined) {
          const responseData = await fetchGroupsById(this.gradeId);
          const materiaProfesor = responseData.materia_profesor;
          this.subjectTeacher = materiaProfesor;
          this.studentArray = responseData.alumnos;
          for (const subjectTeacher of materiaProfesor) {
            this.materiaProfesorId = subjectTeacher.id;
            if (this.employeeId === subjectTeacher.profesor.id) {
              this.subjectArray.push(subjectTeacher.materia);
            }
          }
          this.group = responseData;
        } else {
          const responseData = await fetchGroupsById(this.gradeId);
          const materiaProfesor = responseData.materia_profesor;
          this.subjectTeacher = materiaProfesor;
          this.studentArray = responseData.alumnos;
          for (const subjectTeacher of materiaProfesor) {
            this.subjectArray.push(subjectTeacher.materia);
          }
          this.group = responseData;
        }
      } catch (error) {
        console.error('Error al cargar los alumnos:', error);
      }
    },
    getCriteriosBySubject(subjectId) {
      const subject = this.subjectTeacher.find(
        (sub) => sub.materia.id === subjectId
      );
      if (subject !== undefined) {
        this.studentArray.map((student) => {
          student.criterios_evaluacion = subject.criterios_evaluacion;
          student.criterios_evaluacion.map((c) => (c.nuevoPorcentaje = 0));
          student.criterios_evaluacion.map((t) => (t.total = 0));
          student.criterios_evaluacion.map((t) => (t.promedio = 0));
          student.criterios_evaluacion.map((t) => (t.qualitativeRating = 0));
          student.criterios_evaluacion.map(
            (t) => (t.comentarios = this.dataComentario[1])
          );
        });
      }
      return subject ? subject.criterios_evaluacion : [];
    },
    //#endregion
    //#region tabla expandida
    onSelect(selectedItems) {
      if (selectedItems.length > 0) {
        this.selectedStudentIds = selectedItems;
        this.getPaginatedSubjectStudents(1);
        this.selectedStudentId = selectedItems[0].id;
        this.studentId = this.selectedStudentIds[0].id;
      } else {
        this.selectedStudentId = null;
      }
    },
    isItemSelected(itemId) {
      return this.selectedStudentId === itemId;
    },

    onSelectQualification(selectedItems) {
      if (selectedItems.length > 0) {
        this.selectedQualifications = selectedItems;
        //this.getPaginatedSubjectStudents(1);
        this.selectedQualification = selectedItems[0].id;
        this.qualificationById = this.selectedQualifications[0].id;
      } else {
        this.selectedQualification = null;
      }
    },
    isItemSelectedQualification(itemId) {
      return this.selectedQualification === itemId;
    },
    /*onSelect(selectedItems) {
      if (selectedItems.length > 0) {
        this.selectedStudentIds = selectedItems.map(item => item.id);
        this.getPaginatedSubjectStudents(1);
      } else {
        this.selectedStudentId = null;
      }
    },
    isItemSelected(itemId) {
      // Verifica si el item está en la lista de seleccionados
      if(!itemId) return;
      return this.selectedStudentIds.includes(itemId);
    },*/
    onItemExpanded({ item, value }) {
      this.expandedItemId = value ? item.id : null;
      this.getCriteriosBySubject(this.expandedItemId);
      this.studentArray.forEach((completeName) => {
        completeName.nombreCompleto = getFullName(
          completeName.datos_personales
        );
        completeName.isSend = false;
      });
      for (const borrador of this.qualifications) {
        // Filtrar el array de estudiantes eliminando aquellos cuyo ID sea diferente a borrador.materia_alumno.alumno
        this.studentArray = this.studentArray.filter((estudiante) => {
          if (borrador.materia_alumno.alumno !== estudiante.id) {
            return false; // Excluir del array
          } else {
            return true; // Mantener en el array
          }
        });
      }
    },
    toggleExpand(item) {
      const index = this.expandedItems.indexOf(item);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(item);
      }
    },
    //#endregion
    //#region POST calificaciones
    async createSingleGrades() {
      this.showAlert = true;

      // Validación para id_periodo y fecha_evaluacion
      if (
        !this.calificaciones.id_periodo ||
        !this.calificaciones.fecha_evaluacion
      ) {
        this.setWarningState(
          'Debe asignar un periodo y una fecha de evaluación antes de continuar.'
        );
        return; // Salir de la función si no están asignados
      }
      for (const criterio of this.selectedStudentIds) {
        for (const evaluacion of criterio.criterios_evaluacion) {
          if (evaluacion.nuevoPorcentaje > evaluacion.porcentaje) {
            this.setWarningState(
              'No se puede asignar una calificación mayor al porcentaje ya establecido al criterio.'
            );
            return; // Salir de la función si no están asignados
          }
        }
      }
      this.setLoadingState(true, 'Creando calificaciones..', 'info');

      try {
        const detalle_calificaciones = [];
        const currentDate = new Date().toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        // Recorre los criterios de los estudiantes seleccionados
        for (const criterio of this.selectedStudentIds) {
          for (const evaluacion of criterio.criterios_evaluacion) {
            detalle_calificaciones.push({
              id_criterio_evaluacion: evaluacion.id,
              puntaje_obtenido: evaluacion.nuevoPorcentaje || 0,
              puntaje_total: evaluacion.porcentaje || 0,
            });
          }
        }

        // Validación de subjectStudents
        if (!this.subjectStudents || this.subjectStudents.length === 0) {
          this.alertType = this.successAlertType;
          this.alertColor = 'info';
          this.alertText =
            'No se encontraron estudiantes asignados a la materia.';
          this.loading = false;
          return;
        }
        const estatusCalificaciones = await getItem(
          `/app-personas/filters/estatus-calificacion?dato=Pendiente&estatus_sistema=true`
        );
        const idEstatus =
          estatusCalificaciones.results.length > 0
            ? estatusCalificaciones.results[0].id
            : null;
        console.log('Estatus', idEstatus);
        // Datos para la creación de calificaciones
        const gradeData = {
          id_boleta: 1,
          id_materia_alumno: this.subjectStudents[0].id,
          id_periodo: this.calificaciones.id_periodo,
          id_estatus_calificacion: idEstatus,
          id_institucion_educativa: this.institutionId,
          fecha_evaluacion: currentDate,
          comentarios: this.dataComentario[1],
          detalle_calificaciones: detalle_calificaciones,
        };

        // Llamada a la API para crear las calificaciones
        const { ok, message } = await gradesPost(gradeData);

        if (!ok) {
          console.error('Error en la respuesta de la API:', message);
          this.errors.push(message);
        } else {
          this.setSuccessState('La calificación se ha creado');
          for (const student of this.studentArray) {
            for (const studentCriteria of student.criterios_evaluacion) {
              studentCriteria.nuevoPorcentaje = 0;
            }
          }
          this.clearSelection();
          this.criteriaTotal = false;
          this.selectedStudentIds = [];
          this.dataComentario = [];
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar crear calificaciones: ' + error.message
        );
        console.error('Error al crear calificaciones:', error);
      } finally {
        this.loading = false;
      }
    },
    async createGrades() {
      this.showAlert = true;

      // Validación para id_periodo y fecha_evaluacion
      if (
        !this.calificaciones.id_periodo ||
        !this.calificaciones.fecha_evaluacion
      ) {
        this.setWarningState(
          'Debe asignar un periodo y una fecha de evaluación antes de continuar.'
        );
        return; // Salir de la función si no están asignados
      }
      for (const criterio of this.selectedStudentIds) {
        for (const evaluacion of criterio.criterios_evaluacion) {
          if (evaluacion.nuevoPorcentaje > evaluacion.porcentaje) {
            this.setWarningState(
              'No se puede asignar una calificación mayor al porcentaje ya establecido al criterio.'
            );
            return; // Salir de la función si no están asignados
          }
        }
      }
      this.setLoadingState(true, 'Creando calificaciones..', 'info');

      try {
        const detalle_calificaciones = [];
        const currentDate = new Date().toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        // Recorre los criterios de los estudiantes seleccionados
        for (const criterio of this.selectedStudentIds) {
          for (const evaluacion of criterio.criterios_evaluacion) {
            detalle_calificaciones.push({
              id_criterio_evaluacion: evaluacion.id,
              puntaje_obtenido: evaluacion.nuevoPorcentaje || 0,
              puntaje_total: evaluacion.porcentaje || 0,
            });
          }
        }

        // Validación de subjectStudents
        if (!this.subjectStudents || this.subjectStudents.length === 0) {
          this.alertType = this.successAlertType;
          this.alertColor = 'info';
          this.alertText =
            'No se encontraron estudiantes asignados a la materia.';
          this.loading = false;
          return;
        }
        const estatusCalificaciones = await getItem(
          `/app-personas/filters/estatus-calificacion?dato=Borrador&estatus_sistema=true`
        );
        const idEstatus =
          estatusCalificaciones.results.length > 0
            ? estatusCalificaciones.results[0].id
            : null;
        // Datos para la creación de calificaciones
        const gradeData = {
          id_boleta: 1,
          id_materia_alumno: this.subjectStudents[0].id,
          id_periodo: this.calificaciones.id_periodo,
          id_estatus_calificacion: idEstatus,
          id_institucion_educativa: this.institutionId,
          fecha_evaluacion: currentDate,
          comentarios: this.dataComentario[1],
          detalle_calificaciones: detalle_calificaciones,
        };

        // Llamada a la API para crear las calificaciones
        const { ok, message } = await gradesPost(gradeData);

        if (!ok) {
          console.error('Error en la respuesta de la API:', message);
          this.errors.push(message);
        } else {
          this.setSuccessState('La calificación se ha creado en borrador');
          for (const student of this.studentArray) {
            for (const studentCriteria of student.criterios_evaluacion) {
              studentCriteria.nuevoPorcentaje = 0;
            }
          }
          this.clearSelection();
          this.criteriaTotal = false;
          this.selectedStudentIds = [];
          this.dataComentario = [];
          this.getPaginatedFetchQualifications(1);
          this.getPaginatedQualification(1);
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar crear calificaciones: ' + error.message
        );
        console.error('Error al crear calificaciones:', error);
      } finally {
        this.loading = false;
      }
    },
    async updateSingleGrades() {
      this.showAlert = true;

      // Validación para id_periodo y fecha_evaluacion
      if (
        !this.calificaciones.id_periodo ||
        !this.calificaciones.fecha_evaluacion
      ) {
        this.setWarningState(
          'Debe asignar un periodo y una fecha de evaluación antes de continuar.'
        );
        return; // Salir de la función si no están asignados
      }
      for (const criterio of this.qualifications) {
        for (const evaluacion of criterio.criterios_evaluacion) {
          if (evaluacion.puntaje_obtenido > evaluacion.porcentaje) {
            this.setWarningState(
              'No se puede asignar una calificación mayor al porcentaje ya establecido al criterio.'
            );
            return; // Salir de la función si no están asignados
          }
        }
      }
      this.setLoadingState(true, 'actualizando calificaciones..', 'info');

      try {
        const detalle_calificaciones = [];
        const currentDate = new Date().toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        // Recorre los criterios de los estudiantes seleccionados
        for (const criterio of this.qualifications) {
          for (const evaluacion of criterio.criterios_evaluacion) {
            detalle_calificaciones.push({
              id_criterio_evaluacion: evaluacion.id,
              puntaje_obtenido: evaluacion.puntaje_obtenido || 0,
              puntaje_total: evaluacion.porcentaje || 0,
            });
          }
        }

        // Validación de subjectStudents
        if (!this.qualifications || this.qualifications.length === 0) {
          this.alertType = this.successAlertType;
          this.alertColor = 'info';
          this.alertText =
            'No se encontraron calificaciones asignadas a la materia.';
          this.loading = false;
          return;
        }
        const estatusCalificaciones = await getItem(
          `/app-personas/filters/estatus-calificacion?dato=Pendiente&estatus_sistema=true`
        );
        const idEstatus =
          estatusCalificaciones.results.length > 0
            ? estatusCalificaciones.results[0].id
            : null;
        console.log('Estatus', idEstatus);
        // Datos para la creación de calificaciones
        const gradeData = {
          id_calificacion: this.qualificationById,
          id_boleta: 1,
          id_materia_alumno: this.qualifications[0].materia_alumno.id,
          id_periodo: this.calificaciones.id_periodo,
          id_estatus_calificacion: idEstatus,
          id_institucion_educativa: this.institutionId,
          fecha_evaluacion: currentDate,
          comentarios: this.dataComentario[1],
          detalle_calificaciones: detalle_calificaciones,
        };

        // Llamada a la API para crear las calificaciones
        const { ok, message } = await gradesUpdate(gradeData);

        if (!ok) {
          console.error('Error en la respuesta de la API:', message);
          this.errors.push(message);
        } else {
          this.setSuccessState('La calificación se ha actualizado');
          this.qualifications = [];
          this.dataComentario = [];
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar editar calificaciones: ' + error.message
        );
        console.error('Error al editar calificaciones:', error);
      } finally {
        this.loading = false;
      }
    },
    //#endregion
    //#region procesar calificaciones y criterios de evaluacion
    actualizarTotal(criterio, index, criterioId) {
      // Verifica si se proporcionó el ID del criterio

      if (!criterioId) {
        console.error('No se ha proporcionado un ID del criterio.');
        return;
      }
      // Referencias a los elementos correspondientes del DOM
      const criterioInput = this.$refs[`criterio-${criterioId}-${index}`];
      const totalContainer = this.$refs[`total-${criterioId}-${index}`];

      // Verifica que las referencias existen
      if (criterioInput && totalContainer) {
        const { porcentaje, nuevoPorcentaje, qualitativeRating } = criterio;

        // Si se selecciona una calificación cualitativa, aplica los valores correspondientes
        if (qualitativeRating) {
          criterio.total = this.getQualitativeTotal(
            criterio,
            qualitativeRating
          );
        } else {
          // Calcula el total para el tipo numérico (si no es cualitativa)
          criterio.total = this.calculateNumericTotal(
            nuevoPorcentaje,
            porcentaje
          );
        }

        const total = criterio.total;

        // Actualiza el contenido del contenedor con el nuevo total
        this.updateTotalDisplay(totalContainer, total);
        this.processCriterio(criterio);
      } else {
        console.error(
          `No se encontraron las referencias para el criterio con id ${criterioId} y el índice ${index}`
        );
      }
    },
    // Método para obtener el total basado en la calificación cualitativa
    getQualitativeTotal(criterio, qualitativeRating) {
      if (!criterio && !qualitativeRating) return;
      const excelente = 100;
      const muyBueno = 95;
      const bueno = 85;
      const regular = 75;
      const insuficiente = 50;
      switch (qualitativeRating) {
        case 'Excelente':
          criterio.nuevoPorcentaje = Math.trunc(
            excelente * (criterio.porcentaje / 100)
          );
          return excelente;
        case 'Muy bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            muyBueno * (criterio.porcentaje / 100)
          );
          return muyBueno;
        case 'Bueno':
          criterio.nuevoPorcentaje = Math.trunc(
            bueno * (criterio.porcentaje / 100)
          );
          return bueno;
        case 'Regular':
          criterio.nuevoPorcentaje = Math.trunc(
            regular * (criterio.porcentaje / 100)
          );
          return regular;
        case 'Insuficiente':
          criterio.nuevoPorcentaje = Math.trunc(
            insuficiente * (criterio.porcentaje / 100)
          );
          return insuficiente;
        default:
          return 0; // Valor por defecto si no coincide ninguna opción
      }
    },
    calculateNumericTotalEvaluationCualitativa(nuevoPorcentaje, porcentaje) {
      // Verifica que 'nuevoPorcentaje' y 'porcentaje' sean números válidos
      const nuevoPorcentajeNum = parseFloat(nuevoPorcentaje);
      const porcentajeNum = parseFloat(porcentaje);

      if (
        !isNaN(nuevoPorcentajeNum) &&
        !isNaN(porcentajeNum) &&
        porcentajeNum > 0
      ) {
        return (
          Math.trunc(((nuevoPorcentajeNum * 100) / porcentajeNum) * 100) / 100
        );
      } else {
        console.error(
          'Datos inválidos para cálculo numérico:',
          nuevoPorcentaje,
          porcentaje
        );
        return 0;
      }
    },
    // Método para calcular el total basado en porcentaje numérico
    calculateNumericTotal(nuevoPorcentaje, porcentaje) {
      if (!nuevoPorcentaje || !porcentaje) {
        return 0; // Si no hay datos, el total es 0
      }
      return (
        Math.trunc(
          ((Number(nuevoPorcentaje) * 100) / Number(porcentaje)) * 100
        ) / 100
      );
    },

    // Método para actualizar el contenido del contenedor de total
    updateTotalDisplay(totalContainer, total) {
      // Limpia el contenido existente
      totalContainer[0].innerHTML = '';

      // Crea el nuevo elemento de texto con el total
      const text = document.createElement('p');
      text.textContent = `total: ${total}`;

      // Inserta el nuevo elemento en el contenedor
      totalContainer[0].appendChild(text);
    },

    processCriterio(criterio) {
      const total = criterio.total;
      this.totalArray.push(total);
      criterio.promedio = this.cularPromedioTotal(this.totalArray);
      this.promedio = criterio.promedio;
    },
    cularPromedioTotal(objeto) {
      // Verificar que el objeto tiene la propiedad "total"

      // Calcular el promedio de los valores en la propiedad "total"
      let sumaTotal = 0;

      // Sumar todos los valores en la propiedad "total" usando un bucle for
      for (let i = 0; i < objeto.length; i++) {
        sumaTotal += objeto[i];
      }

      // Calcular el promedio
      const promedio = sumaTotal / objeto.length;

      // Truncar el promedio a 2 decimales
      const promedioTruncado = Math.floor(promedio * 100) / 100;
      return promedioTruncado;
    },
    //#endregion
    //#region validaciones
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success') {
        this.returnToTable();
      } else if (this.alertType === 'warning' || this.alertType === 'info') {
        this.closeAlert();
      } else {
        this.returnToTable();
      }
    },
    continueAdding() {
      this.selectedStudentIds = [];
      this.closeAlert();
      this.clearSelection();
    },
    onComentarioInput(value, itemId) {
      this.dataComentario[itemId] = value;
      // Aquí puedes decidir si quieres hacer algo más que pueda afectar el primer template
    },
    updateComentario(id, value) {
      this.dataComentario[id] = value;
    },
    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
    getGroupImage(group) {
      if (!group || !group.imagen_grupo || !group.imagen_grupo.imagen) {
        return require('../../../../assets/images/huellita.png'); // Default image
      } else {
        return group.imagen_grupo.imagen;
      }
    },
    getSchoolYear(group) {
      if (!group || !group.ciclo_escolar || !group.ciclo_escolar.dato) {
        return 'Estimulación temprana';
      } else {
        return group.ciclo_escolar.dato;
      }
    },
    //#endregion
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
      this.alertText = '';
      this.alertType = '';
      this.alertColor = '';
    },
    //#endregion
    cancel() {
      this.returnToTable();
    },
    clearSelection() {
      this.selectedStudentIds = [];
      this.selectedStudentId = null;
      this.studentId = null;
    },
    saveStudents() {
      // Recupera el array existente de estudiantes desde localStorage
      const existingStudents =
        JSON.parse(localStorage.getItem('students')) || [];

      // Filtra los nuevos estudiantes que no estén ya en el array
      const newStudents = this.selectedStudentIds.filter(
        (student) =>
          !existingStudents.some((existing) => existing.id === student.id)
      );

      // Combina los arrays de estudiantes existentes y nuevos
      const updatedStudents = [...existingStudents, ...newStudents];

      // Guarda el array actualizado en localStorage
      localStorage.setItem('students', JSON.stringify(updatedStudents));
      this.setWarningState('Su calificación se ha guardado en borrador');
    },
  },
};
